import React, { useState, useEffect } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';

const ResultScreen = ({ ocrText, openaiSummary, selectedDifficulty = '중', onBackToStart, photoData }) => {
  const [filteredSummary, setFilteredSummary] = useState('');
  const [buttonClicked, setButtonClicked] = useState(selectedDifficulty);

  useEffect(() => {
    filterSummary(buttonClicked); // Initialize with default or selectedDifficulty when component mounts
  }, [openaiSummary, buttonClicked]);

  const handleClick = (difficulty) => {
    setButtonClicked(difficulty); // Update the active button state
  };

  const filterSummary = (difficulty) => {
    const summaryMap = {
      '상': '상:',
      '중': '중:',
      '하': '하:'
    };

    const summaries = openaiSummary.split('\n');
    const startIndex = summaries.findIndex(line => line.trim() === summaryMap[difficulty]);
    let endIndex = summaries.slice(startIndex + 1).findIndex(line => ['상:', '중:', '하:'].includes(line.trim()));
    endIndex = endIndex === -1 ? summaries.length : endIndex + startIndex + 1;

    const filtered = summaries.slice(startIndex + 1, endIndex).join('\n').trim();
    if (filtered) {
      setFilteredSummary(filtered);
    } else {
      console.error('No matching summaries found for difficulty:', difficulty);
      setFilteredSummary('No summary available for this difficulty level.');
    }
  };

  const speakText = (text, lang = 'ko-KR') => {
    window.speechSynthesis.cancel(); // Stops any currently speaking utterance
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = lang;
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className="result-screen">
      <h2>결과</h2>
      <div className="photo-section my-3">
        <img src={photoData} alt="Captured" style={{ width: '100%', maxHeight: '600px', objectFit: 'contain' }} />
      </div>
      <div className="summary-section">
        <Card>
          <Card.Header>Summary</Card.Header>
          <ListGroup variant="flush">
            {filteredSummary.split('\n').map((item, index) => (
              <ListGroup.Item key={index}>{item}</ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </div>
      <div className="button-container my-3">
        {['하', '중', '상'].map((level) => (
          <Button key={level} variant={buttonClicked === level ? level === '상' ? "danger" : level === '중' ? "warning" : "primary" : "outline-secondary"} onClick={() => handleClick(level)}>
            {level}
          </Button>
        ))}
        <Button variant="info" onClick={() => speakText(filteredSummary)}>Read Summary</Button>
      </div>
      <Button variant="secondary" onClick={onBackToStart} className="mt-3">Back to Start</Button>
    </div>
  );
};

export default ResultScreen;
